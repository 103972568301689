<script setup>
const props = defineProps(["filter", "filtersStore", "countryId"]);
const value = ref('');
const value_string = ref('');
const cities_options = ref([]);
const districts_options = ref([]);
const neighborhoods_options = ref([]);
const selected_city = ref(props.filtersStore.selected_filters[props.filter.slug]?.selected_city || null);
const selected_district = ref(props.filtersStore.selected_filters[props.filter.slug]?.selected_district || null);
const selected_neighborhood = ref(props.filtersStore.selected_filters[props.filter.slug]?.selected_neighborhood || null);
const fetchCitiesOptions = async (parentId) => {
  return await $fetch(`/api/data/locations?parent_id=${parentId}&country_id=${props.countryId}`).catch(() => []);
};
const updateCity = async (option) => {
  value.value = option && option.url;
  value_string.value = option && option.name; 
  districts_options.value = await fetchCitiesOptions(option._id).catch(() => []);
  if (selected_city.value) {
    updateValue();
  }

  resetDistrict();
  resetNeighborhood();
};
const updateDistrict = async (option) => {
  value.value = option && option.url;
  value_string.value = option && option.name; 
  neighborhoods_options.value = await fetchCitiesOptions(option._id).catch(() => []); 
  if (selected_district.value) {
    resetNeighborhood();
    updateValue();
  }
};
const updateNeighborhood = (option) => {
  value.value = option.url;
  value_string.value = option && option.name
  updateValue();
};
const updateValue = () => {
  const slug = value.value || 'turkey';
  const label_value = value_string.value || 'Turkey';
  props.filtersStore.updateValue(props.filter, {
    label_value: label_value,
    slug: slug,
  });
};
const resetDistrict = () => {
  selected_district.value = null;
};
const resetNeighborhood = () => {
  selected_neighborhood.value = null;
};

onMounted(async () => {
  if (selected_neighborhood.value) {
    value.value = selected_neighborhood.value.url;
    districts_options.value = await fetchCitiesOptions(selected_city.value._id);
    neighborhoods_options.value = await fetchCitiesOptions(selected_district.value._id)
  } else if (selected_district.value) {
    value.value = selected_district.value.url;
    districts_options.value = await fetchCitiesOptions(selected_city.value._id);
    neighborhoods_options.value = await fetchCitiesOptions(selected_district.value._id)
  } else if (selected_city.value) {
    value.value = selected_city.value.url;
    districts_options.value = await fetchCitiesOptions(selected_city.value._id);
  }

  cities_options.value = await fetchCitiesOptions(null);
  updateValue();
})
</script>


<template>
  <div class="px-3 my-3">
    {{ filter.name }}
  </div>
  <div class="flex flex-col gap-4 border-b border-b-slate-200 pb-3">
    <div class="flex flex-col px-3">
      <span v-if="filtersStore?.showInputLabel" class="text-neutral-400 text-xs font-normal mb-2 ms-1">
        {{ filter.name }}
      </span>
      <WInput 
        :select="true"
        :options="cities_options"
        v-model:value="selected_city"
        class="bg-white rounded-md ring-1 ring-gray-200"
        type="select" 
        name="city"
        style=""
        :placeholder="$t('client.city')"
        trackBy="_id"
        label="name"
        @update:item="updateCity"
      />
    </div>

    <div class="flex flex-col px-3" v-if="selected_city && districts_options.length">
      <span v-if="filtersStore?.showInputLabel" class="text-neutral-400 text-xs font-normal mb-2 ms-1">
        {{ filter.name }}
      </span>
      <WInput 
        :select="true"
        :options="districts_options"
        v-model:value="selected_district"
        class="bg-white rounded-md ring-1 ring-gray-200 my-2"
        type="select" 
        name="districts"
        style=""
        :placeholder="$t('client.selected_district')"
        trackBy="_id"
        label="name"
        @update:item="updateDistrict"
      />
    </div>

    <div class="flex flex-col px-3" v-if="selected_district && neighborhoods_options.length">
      <span v-if="filtersStore?.showInputLabel" class="text-neutral-400 text-xs font-normal mb-2 ms-1">
        {{ filter.name }}
      </span>
      <WInput 
        :select="true"
        :options="neighborhoods_options || []"
        v-model:value="selected_neighborhood"
        class="bg-white rounded-md ring-1 ring-gray-200 my-2"
        type="select" 
        name="neighborhood"
        style=""
        :placeholder="$t('client.selected_neighborhood')"
        trackBy="_id"
        label="name"
        @update:item="updateNeighborhood"
      />
      
    </div>
  </div>
</template>